html,
body,
#root,
#root > div {
    width: 100%;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input {
    outline: 0;
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(static/fonts/montserrat-latin-700.woff2) format("woff2");
    src: url(static/fonts/montserrat-latin-700.woff) format("woff");
}

@font-face {
    font-family: NunitoSans-Regular;
    src: url(static/fonts/nunito-latin-300.woff2) format("woff2");
    src: url(static/fonts/nunito-latin-300.woff) format("woff");
}

@font-face {
    src: url(static/fonts/MaterialIcons.ttf);
    font-family: MaterialIcons;
}

@font-face {
    src: url(static/fonts/FontAwesome5_Solid.ttf);
    font-family: FontAwesome5_Solid;
}

@font-face {
    src: url(static/fonts/FontAwesome5_Regular.ttf);
    font-family: FontAwesome5_Regular;
}

@font-face {
    src: url(static/fonts/MaterialCommunityIcons.ttf);
    font-family: MaterialCommunityIcons;
}
